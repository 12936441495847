import {
	useMemo,
	useState,
	useEffect
} from "react";
import {
	Form,
	Formik,
	useFormikContext
} from "formik";

import {FormCell} from "./book";

import styled, {
	css,
	keyframes,
	ThemeProvider
} from "styled-components";
import { AuthState } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import { transparentize } from "polished";

import {
	DAYS,
	TIMEZONES,
	FULL_DAY_OPTIONS,
	FULL_VENUE_OPTIONS,
	MEETING_TYPE_OPTIONS,
	FULL_DURATION_OPTIONS,
	TOPIC_OPTIONS,
	BUFFER_TIME_OPTIONS,
	ONETIME_DURATION_OPTIONS,
	ACTIVE_DAYS,
	ACTIVE_WEEK_DAYS
} from "../data/constants";

import {
	mkDate,
	printTime,
	printDate,
	mkTimeString,
	mkDateString,
	getUtcDayCode,
	parseTimeString,
	parseDateString,
	getLocalizedTime
} from "../util/time";
import { request } from "../util/async";

import availabilityTheme from "../themes/availability";

import {
	Text,
	Time as TimeInput,
	Date as DateInput,
	Check,
	Select,
	Toggle,
	TzSelect,
	LinearSelect
} from "../components/inputs";
import CalendarView, {
	UpdateEvent,
	SelectEvent,
	RemoveEvent,
	DaySelectEvent
} from "../components/calendar-view";
import Tip from "../components/tip";
import Card from "../components/card";
import Icon from "../components/icon";
import { default as TimeDisplay } from "../components/time";
import Modal from "../components/modal";
import Button from "../components/button";
import Centered from "../components/centered";
import LoadingIcon from "../components/loading-icon";

import {
	useAppDispatch,
	useAppSelector
} from "../state/hooks";
import {
	setEvents,
	setEmployee
} from "../state/features";

import {
	Attendee,
	VenueType,
	ScheduledTime,
	ScheduledPoint,
	RecurrenceMode,
	EventTemplateType,
	ScheduledExactTime,
	ClientEventTemplate
} from "../../../types/events";
import {
	Availability,
	AvailabilityDay
} from "../../../types/employee";
import {
	Option,
	FormEvent,
	OptionObject
} from "../types/forms";
import { Time } from "../types/time";
import { IconName } from "../types/icon";
import { Response } from "../types/async";
import { TimeEvent } from "../util/time-blocks";
import {message, Switch, Alert} from 'antd';
import useGAEventsTracker from "../hooks/useGAEventsTracker";
import { Popconfirm } from 'antd';


interface EventsTableProps {
	employeeId: string;
	events: ClientEventTemplate[];
	localTimeZone: string;
	pendingDeletions: string[];
	onDelete: (event: ClientEventTemplate) => void;
	onUpdate: (event: ClientEventTemplate) => void;
	onEventSelect: (event: ClientEventTemplate) => void;
	onEventTrigger: () => void;
}

interface EventProps {
	index: number;
	disabled: boolean;
	employeeId: string;
	event: ClientEventTemplate;
	localTimeZone: string;
	onUpdate: (event: ClientEventTemplate) => void;
	onDelete: (event: ClientEventTemplate) => void;
	onEventSelect: (event: ClientEventTemplate) => void;
}

interface EventDataPointProps {
	index: number;
	faded?: boolean;
	children: any;
}

interface EventDataPointWrapperProps {
	index: number;
}

interface EventDataPointContentProps {
	faded?: boolean;
}

interface EventLinkProps {
	onCopy: () => void;
	onRefresh?: () => void;
	copying?: boolean;
	refreshing?: boolean;
	children: any;
}

interface EventLinkActionButtonProps {
	disabled?: boolean;
	animating?: boolean;
}

interface ScheduledLabelProps {
	scheduled: ScheduledPoint[];
	localTimeZone: string;
	remoteTimeZone: string;
}

interface EventEditorProps {
	event: ClientEventTemplate;
	disabled: boolean;
	onSave: (event: ClientEventTemplate) => void;
	onError: (message: string) => void;
	authData: AuthData;
}

interface AttendeeSelectorProps {
	prompt: string;
	attendees: Attendee[];
	onChange: (attendees: Attendee[]) => void;
	teammatesOnly: boolean;
	authData: AuthData;
}

interface AttendeeListingProps {
	title: string;
	attendees: Attendee[];
	onChange: (attendees: Attendee[]) => void;
	checkable?: boolean;
}

interface AttendeeEntryProps {
	attendee: Attendee;
	onToggle: (attendee: Attendee) => void;
	onDelete: (attendee: Attendee) => void;
	checkable?: boolean;
}

interface InputRowProps {
	expanded?: boolean;
}

interface AnyIcon {
	any: IconName;
}

interface AnyLabel {
	any: string;
}

interface AuthData {
	employeeId: string;
	state: AuthState;
}

const EVENT_TYPE_ICON_MAP: Record<EventTemplateType, IconName> = {
	me: "user",
	team: "users",
	"office-hour": "pin",
};

const VENUE_TYPE_ICON_MAP: Record<VenueType, IconName> & AnyIcon = {
	zoom: "camera",
	"google-meet": "camera",
	phone: "phone",
	any: "controls"
};

const VENUE_TYPE_LABEL_MAP: Record<VenueType, string> & AnyLabel = {
	zoom: "Zoom",
	"google-meet": "Meet",
	phone: "Phone",
	any: "Any"
}

const AccountWrapper = styled.div`
	padding: 20px;
	margin: 0 auto;
	width: 100%;
	max-width: 950px;

	h2 {
		font-size: 130%;
		margin: 0 0 6px;
		border-bottom: ${p => p.theme.boundaryBorder};
		padding-bottom: 4px;
	}

	h3 {
		font-size: 110%;
		margin: 0.5em 0 0.2em 0;
	}

	p {
		margin: 0.2em 0 0.7em 0;
	}

	.calendar-view {
		min-height: 500px;

		.selected-slot {
			text-shadow: 0 1px 2px rgba(0, 34, 41, 0.4);
			background-image: repeating-linear-gradient(-15deg, transparent 0, transparent 5px, #05b7dc 5px, #05b7dc 10px);
		}
	}
`;

const SettingBox = styled.div`
	margin: 0 0 1.5em;
`;

const EventsCard = styled(Card)`
	padding: 5px 10px 10px;
`;

const AddButton = styled(Button)`
	height: 28px;
	padding: 5px 0;
	width: 100%;
`;

const EventsTableWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(5, auto) repeat(2, max-content);
	max-height: 400px;
	overflow: auto;
`;

const EventsTableHeading = styled.div`
	display: flex;
	align-items: center;
	position: sticky;
	top: 0;
	font-weight: bold;
	padding: 5px 5px 10px;
	margin-bottom: 5px;
	border-bottom: 1px solid ${p => p.theme.separatorColor};
	background: ${p => p.theme.cardBackground};
	z-index: 100;
`;

const EventsTable = (props: EventsTableProps) => {
	const events = props.events.map((event, index) => (
		<Event
			key={index}
			event={event}
			index={index}
			disabled={props.pendingDeletions.includes(event.id)}
			employeeId={props.employeeId}
			localTimeZone={props.localTimeZone}
			onDelete={props.onDelete}
			onUpdate={props.onUpdate}
			onEventSelect={props.onEventSelect}
		/>
	));

	return (
		<EventsTableWrapper>
			<EventsTableHeading>Event</EventsTableHeading>
			<EventsTableHeading>Attendees</EventsTableHeading>
			<EventsTableHeading>Scheduled</EventsTableHeading>
			<EventsTableHeading>Duration</EventsTableHeading>
			<EventsTableHeading>Venue</EventsTableHeading>
			<EventsTableHeading>Links</EventsTableHeading>
			<EventsTableHeading>
				<AddButton
					square
					onClick={props.onEventTrigger}
				>
					Add
				</AddButton>
			</EventsTableHeading>
			{events}
		</EventsTableWrapper>
	);
};

const EventDataPointWrapper = styled.div<EventDataPointWrapperProps>`
	display: flex;
	padding: 5px;
	line-height: 1;
	border-top: ${p =>
		p.index ?
			`1px solid ${p.theme.separatorColor}` :
			null
	};
`;

const EventDataPointContent = styled.div<EventDataPointContentProps>`
	display: flex;
	align-items: center;
	gap: 10px;
	opacity: ${p => p.faded ? 0.6 : 1};
`;

const EventDataPoint = (props: EventDataPointProps) => {
	return (
		<EventDataPointWrapper index={props.index}>
			<EventDataPointContent
				faded={props.faded}
			>
				{props.children}
			</EventDataPointContent>
		</EventDataPointWrapper>
	);
};

const EventDataIcon = styled(Icon)`
	flex-shrink: 0;
	width: 15px;
	margin-right: -5px;
	color: ${p => p.theme.extraLightColor};
`;

const EventLinkWrapper = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	height: 28px;
	background: ${p => p.theme.background};
	padding: 5px;
	border: 1px solid ${p => p.theme.separatorColor};
	border-radius: ${p => p.theme.borderRadius};
`;

const EVENT_LINK_COPY_KEYFRAMES = keyframes`
	0% { opacity: 0 }
	10% { opacity: 0.4 }
	100% { opacity: 0 }
`;

const EventLinkCopyOverlay = styled.div`
	position: absolute;
	top: -1px;
	bottom: -1px;
	left: -1px;
	right: -1px;
	border-radius: inherit;
	border: 1px solid ${p => p.theme.popBackgroundAlt};
	background: ${p => transparentize(0.6, p.theme.popBackgroundAlt)};
	animation: ${EVENT_LINK_COPY_KEYFRAMES} 1s 1;
	opacity: 0;
	pointer-events: none;
`;

const EventLinkContent = styled.div`
	margin: 0 4px;
`;

const EVENT_LINK_ANIMATION_KEYFRAMES = keyframes`
	to {
		transform: rotate(1turn);
	}
`;

const EVENT_LINK_ANIMATION = css`${EVENT_LINK_ANIMATION_KEYFRAMES} 1s linear infinite`;

const EventLinkActionButton = styled.button<EventLinkActionButtonProps>`
	width: 18px;
	height: 18px;
	padding: 1px;
	border: none;
	outline: none;
	color: inherit;
	background: transparent;
	cursor: ${p => p.disabled ? "not-allowed" : "pointer"};
	animation: ${p => p.animating ? EVENT_LINK_ANIMATION : "none"};
	opacity: ${p => p.disabled ? 0.4 : 1};
	transition: opacity 300ms;
`;

const EventLinkSeparator = styled.div`
	height: 100%;
	margin: 0 3px;
	border-left: 1px solid ${p => p.theme.separatorColor};
`;

const EventLink = (props: EventLinkProps) => {
	const [showOverlay, setShowOverlay] = useState(false);

	const copy = () => {
		props.onCopy();
		setShowOverlay(true);
		setTimeout(() => setShowOverlay(false), 1000);
	};

	let refreshButton = null;

	if (typeof props.onRefresh == "function") {
		refreshButton = (
			<>
				<EventLinkSeparator />
				<Popconfirm
					title="Are you sure to refresh this link?"
					description="This will invalidate previously shared booking links."
					onConfirm={props.onRefresh}
					okText="Yes"
					cancelText="No"
				>
					<EventLinkActionButton
						animating={props.refreshing}
					>
						<Icon name="refresh" />
					</EventLinkActionButton>
				</Popconfirm>
			</>
		);
	}

	const overlay = showOverlay ?
		<EventLinkCopyOverlay /> :
		null;

	return (
		<EventLinkWrapper>
			<EventLinkContent>
				{props.children}
			</EventLinkContent>
			{refreshButton}
			<EventLinkSeparator />
			<EventLinkActionButton
				disabled={props.copying || props.refreshing}
				onClick={copy}
			>
				<Icon name="copy" />
			</EventLinkActionButton>
			{overlay}
		</EventLinkWrapper>
	);
};

const SubduedSpan = styled.span`
	opacity: 0.4;
`;

const ScheduledLabel = (props: ScheduledLabelProps) => {
	if (!props.scheduled.length)
		return <span>N / A</span>;

	const times = props.scheduled.map((pt, idx) => {
		const time = getScheduledTime(
			pt,
			props.localTimeZone,
			props.remoteTimeZone
		);

		return (
			<TimeDisplay
				key={idx}
				time={time}
			/>
		);
	});

	if (times.length === 2) {
		return (
			<span>
				{times[0]}&nbsp;<SubduedSpan>&</SubduedSpan>&nbsp;{times[1]}
			</span>
		);
	}

	if (times.length > 2) {
		return (
			<span>
				{times[0]}&nbsp;+&nbsp;{times.length - 1}
			</span>
		);
	}

	return times[0];
};

const EventActionButton = styled(Button)`
	width: 28px;
	height: 28px;
	padding: 6px;
`;

const EditButton = styled(EventActionButton)`
	background: ${p => p.theme.popBackgroundAlt};

	&:focus {
		box-shadow: 0 0 0 4px ${p => transparentize(0.6, p.theme.popBackgroundAlt)};
	}
`;

const DeleteButton = styled(EventActionButton)`
	background: ${p => p.theme.popBackground};
`;

const WeekendContainer = styled.div`
	display: flex;
	width: 100%;
	gap: 0.5rem;
	justify-content: flex-end;
	margin: 0.5rem 0;
`;

const Event = (props: EventProps) => {
	const { authState } = useOktaAuth();

	const [staticUpdating, setStaticUpdating] = useState(false);
	const [dynamicUpdating, setDynamicUpdating] = useState(false);

	const e = props.event;
	const clickEvent = useGAEventsTracker('Event Actions');

	const handleStaticCopy = () => {
		clickEvent('Copy Static Link');
		copy(e.staticLink);
	};

	const handleStaticRefresh = async () => {
		setStaticUpdating(true);
		const response = await updateLink("employee/link/static/update");
		setStaticUpdating(false);

		if (response.success) {
			clickEvent('Refresh Static Link');
			props.onUpdate(response.data);
		}
	};

	const handleAliasCopy = () => {
		clickEvent('Copy Alias Link');
		copy(e.aliasLink);
	}

	const handleDynamicCopy = async () => {
		setDynamicUpdating(true);
		const response = await updateLink("employee/link/dynamic/update");
		setDynamicUpdating(false);

		if (response.success) {
			clickEvent('Copy One-time Link');
      copy(response.data.dynamicLink);
			props.onUpdate(response.data);
		}
	};

	const copy = (link: string) => {
		navigator.clipboard.writeText(`${window.location.origin}/book/${link}`);
	};

	const updateLink = async (
		url: string
	): Promise<Response> => {
		return request({
			url,
			method: "POST",
			headers: {
				Authorization: authState!.accessToken!.value
			},
			body: {
				employeeId: props.employeeId,
				eventId: e.id
			}
		});
	};

	const participantsLabel = e.attendees.length ?
		(
			<span>
				<strong>{e.attendees.length + 1}</strong>
				&nbsp;attendees
			</span>
		) :
		<span>Me</span>

	const scheduledIconName: IconName = e.scheduled.length ?
		(e.recurring ? "recurring-calendar" : "calendar") :
		"cross";

	const durationIconName: IconName = e.duration === null ?
		"controls" : "clock";

	const durationLabel = e.duration === null ?
		<span>Any</span> :
		(
			<span>
				<strong>
					{e.duration === -1 ? e.customDuration : e.duration}
				</strong>
				&nbsp;minutes
			</span>
		);
	const showCopyAliasLink = !!e.aliasLink
		? <EventLink onCopy={handleAliasCopy}>Custom</EventLink>
		: ''
	// @ts-ignore
	return (
		<>
			<EventDataPoint index={props.index}>
				<EventDataIcon name={EVENT_TYPE_ICON_MAP[e.type]} />
				<strong>{e.title}</strong>
			</EventDataPoint>
			<EventDataPoint index={props.index}>
				<EventDataIcon name={e.attendees.length ? "users" : "user"} />
				{participantsLabel}
			</EventDataPoint>
			<EventDataPoint
				index={props.index}
				faded={!e.scheduled.length}
			>
				<EventDataIcon name={scheduledIconName} />
				<ScheduledLabel
					scheduled={e.scheduled}
					localTimeZone={props.localTimeZone}
					remoteTimeZone={e.timeZone}
				/>
			</EventDataPoint>
			<EventDataPoint index={props.index}>
				<EventDataIcon name={durationIconName} />
				{durationLabel}
			</EventDataPoint>
			<EventDataPoint index={props.index}>
				<EventDataIcon name={VENUE_TYPE_ICON_MAP[e.venue || "any"]} />
				<span>{VENUE_TYPE_LABEL_MAP[e.venue || "any"]}</span>
			</EventDataPoint>
			<EventDataPoint index={props.index}>
				<EventLink
					refreshing={staticUpdating}
					onCopy={handleStaticCopy}
					onRefresh={handleStaticRefresh}
				>
					Static
				</EventLink>
				<EventLink
					copying={dynamicUpdating}
					onCopy={handleDynamicCopy}
				>
					One-time
				</EventLink>
				{showCopyAliasLink}
			</EventDataPoint>
			<EventDataPoint index={props.index}>
				<EditButton
					square
					disabled={props.disabled}
					onClick={() => props.onEventSelect(e)}
				>
					<Icon name="pencil" />
				</EditButton>
				<Popconfirm
					title="Are you sure to delete this event?"
					onConfirm={() => props.onDelete(props.event)}
					okText="Yes"
					cancelText="No"
				>
					<DeleteButton
						square
						disabled={!e.deletable || props.disabled}
					>
						<Icon name="trash" />
					</DeleteButton>
				</Popconfirm>
			</EventDataPoint>
		</>
	);
};

const EventEditorModal = styled(Modal)`
	min-width: 900px;
	min-height: 400px;
`;

const EventEditorWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const EventEditorContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	min-width: 1000px;
	max-width: 90%;
`;

const EventSaveWrapper = styled.div`
	position: sticky;
	bottom: -5px;
	margin-top: 15px;
	padding: 15px 0 5px;
	background: ${p => p.theme.cardBackground};
	border-top: 1px solid ${p => p.theme.separatorColor};
	z-index: 10;
`;

const EventInfoFormWrapper = styled(Form)`
	display: grid;
	grid-template-columns: 1fr auto;
	gap: 10px;
`;

export const InputRow = styled.div<InputRowProps>`
	display: flex;
	gap: 10px;
	// grid-column: ${p => p.expanded ? "1 / span 2" : null};
	flex-grow: 1;
	flex-basis: 0;
`;

const InputRows = styled.div`
	display: flex;
	flex-direction: column;
`;

const ExpandingText = styled(Text)`
	flex-grow: 1;
`;

const ExpandingFormCell = styled(FormCell)`
	flex-grow: 1;
`;

interface EventInfoFormProps {
	settings: EventSettings;
	onChange: (values: EventSettings) => void;
}

type EventSettings = Pick<
	ClientEventTemplate,
	"type" | "duration" | "customDuration" | "title" | "timeZone" | "venue" | "topic" | "description" | "requireDescription" | "oneTimeExpiration" | "aliasLink"
>;

const EventInfoForm = (props: EventInfoFormProps) => {
	const ctx = useFormikContext<EventSettings>();
	const custom = props.settings.type === "me" || props.settings.type === "team";
	const isZoomEvent = props.settings.venue === "zoom";

	useEffect(
		() => props.onChange(ctx.values),
		[ctx.values]
	);

	const durationInput = (
		<FormCell>
			<label htmlFor="duration"  className="required">
				Duration in Minutes
			</label>
			<ExpandingText
				type="number"
				min={15}
				accented
				required
				name="customDuration"
			/>
		</FormCell>
	);

	const duration = (
		<FormCell>
			<label htmlFor="duration">
				Duration
			</label>
			<Select
				accented
				name="duration"
				options={FULL_DURATION_OPTIONS}
			/>
		</FormCell>
	);

	const eventVenue = custom ?
		(
			<FormCell>
				<label htmlFor="venue">
					Conference
				</label>

				<Select
				accented
				name="venue"
				options={FULL_VENUE_OPTIONS}
				/>
			</FormCell>
		) :
		null;

	const requireDescription = (
		<Check
			accented
			name="requireDescription"
			label="Require Additional Information from Customer"
		/>
	);

	const zoomOverrides = isZoomEvent ?
		(
			<>
				<ExpandingFormCell>
					<label htmlFor="zoomLinkOverride">
						Zoom Join Link Override
					</label>

					<ExpandingText
						accented
						name="zoomLinkOverride"
					/>
				</ExpandingFormCell>
				<FormCell>
					<label htmlFor="zoomPasswordOverride">
						Zoom Password Override
					</label>

					<ExpandingText
						accented
						name="zoomPasswordOverride"
					/>
				</FormCell>
			</>
		) :
		null;

	const oneTimeExpiration = (
		<FormCell>
			<label htmlFor="duration">
				One-Time Link Expiration
			</label>
			<Select
				accented
				name="oneTimeExpiration"
				options={ONETIME_DURATION_OPTIONS}
			/>
		</FormCell>
	);

	const eventType = (
		<FormCell>
			<label htmlFor="type">
				Type
			</label>

			<Select
				accented
				name="type"
				options={MEETING_TYPE_OPTIONS}
			/>
		</FormCell>
	);

	const eventTitle = (
		<ExpandingFormCell>
			<label htmlFor="title"  className="required">
				Title
			</label>

			<ExpandingText
				accented
				required
				name="title"
			/>
		</ExpandingFormCell>
	);

	const eventDescription = (
		<ExpandingFormCell>
			<label htmlFor="description">
				Description
			</label>

			<ExpandingText
				accented
				name="description"
			/>
		</ExpandingFormCell>
	);

	const eventTimezone = (
		<FormCell>
			<label htmlFor="timeZone" >
				Timezone
			</label>

			<TzSelect
				accented
				name="timeZone"
			/>
		</FormCell>
	);

	const eventTopic = (
		<FormCell>
			<label htmlFor="topic">
				Topic
			</label>

			<Select
				accented
				name="topic"
				options={TOPIC_OPTIONS}
			/>
		</FormCell>
	);

	const eventLinkAlias = (
		<ExpandingFormCell>
			<label htmlFor="aliasLink">
				Custom Link Id
			</label>

			<ExpandingText
				accented
				name="aliasLink"
				placeholder="Minimum 3 characters. a-Z, 0-9, -, _ allowed. No spaces"
			/>
		</ExpandingFormCell>
	);

	return (
		<EventInfoFormWrapper>
			<InputRows>
				<InputRow>
					{eventTitle}
					{eventType}
					{eventTopic}
				</InputRow>
				<InputRow expanded={false}>
					{eventDescription}
					{requireDescription}
				</InputRow>
				<InputRow>
					{zoomOverrides}
				</InputRow>
				<InputRow>
					{eventVenue}
					{duration}
					{ctx.values.duration === -1 && durationInput}
					{eventTimezone}
					{oneTimeExpiration}
					{eventLinkAlias}
				</InputRow>
			</InputRows>
		</EventInfoFormWrapper>
	);
};

const AttendeeSelectorBox = styled.article`
	display: flex;
	flex-direction: column;
	gap: 15px;
	border: 1px solid ${p => p.theme.separatorColor};
	border-radius: ${p => p.theme.borderRadius};
	padding: 15px;
`;

const AttendeeSearchBoxWrapper = styled.div`
	position: relative;
	z-index: 100;
`;

const AttendeeSearchForm = styled.div`
	display: flex;
	gap: 10px;
	position: relative;
	z-index: 10;
`;

const AttendeeSearchInput = styled(Text)`
	flex-grow: 1;
`;

const AttendeeSearchResultBox = styled.div`
	position: absolute;
	top: -10px;
	left: -10px;
	right: -10px;
	background: ${p => p.theme.background};
	border: ${p => p.theme.boundaryBorder};
	border-radius: ${p => p.theme.borderRadius};
	padding: 55px 10px 10px 10px;
`;

const AttendeeSearchLoadingWrapper = styled(Centered)`
	min-height: 80px;
`;

interface AttendeeSearchBoxProps {
	prompt: string;
	onSelect: (result: Attendee) => void;
	selection: Attendee[];
	teammatesOnly: boolean;
	authData: AuthData;
}

const SEARCH_RESULTS: Attendee[] = [
	{
		type: "teammate",
		name: "John Doe",
		email: "john.doe@paloaltonetworks.com",
		required: true,
		status: "teammate"
	},
	{
		type: "teammate",
		name: "Mary Doe",
		email: "mary.doe@paloaltonetworks.com",
		required: true,
		status: "teammate"
	},
	{
		type: "teammate",
		name: "William Doe",
		email: "william.doe@paloaltonetworks.com",
		required: true,
		status: "teammate"
	},
	{
		type: "shared-calendar",
		name: "PAN Calendar",
		email: "calendar@paloaltonetworks.com",
		required: true,
		status: "teammate"
	},
	{
		type: "shared-calendar",
		name: "PAN Calendar 2",
		email: "calendar2@paloaltonetworks.com",
		required: true,
		status: "teammate"
	},
	{
		type: "shared-calendar",
		name: "PAN Calendar 3",
		email: "calendar3@paloaltonetworks.com",
		required: true,
		status: "teammate"
	}
];

interface AttendeeSearchResultsProps {
	results: Attendee[];
	onSelect: (result: Attendee) => void;
	selection: Attendee[];
	teammatesOnly: boolean;
}

const AttendeeSearchResultsWrapper = styled.div`
	display: flex;
`;

interface SearchResultBoxProps {
	title: string;
	children: any;
}

const SearchResultBoxWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	flex-basis: 0;

	& + & {
		margin-left: 10px;
		padding-left: 10px;
		border-left: 1px solid ${p => p.theme.separatorColor};
	}
`;

const SearchResultBoxTitle = styled.h4`
	font-size: 100%;
	padding-bottom: 5px;
	margin: 0 0 10px;
	border-bottom: 1px solid ${p => p.theme.separatorColor};
`;

const SearchResultBoxContent = styled.div`
	display: flex;
	flex-grow: 1;
`;

const SearchResultBox = (props: SearchResultBoxProps) => {
	return (
		<SearchResultBoxWrapper>
			<SearchResultBoxTitle>{props.title}</SearchResultBoxTitle>
			<SearchResultBoxContent>
				{props.children}
			</SearchResultBoxContent>
		</SearchResultBoxWrapper>
	);
};

interface SearchResultListingProps {
	results: Attendee[];
	onSelect: (attendee: Attendee) => void;
}

const SearchResultListingWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 6px;
`;

const SearchResultEntry = styled.button`
	display: flex;
	align-items: center;
	padding: 4px 8px;
	background: ${p => p.theme.popBackground};
	color: ${p => p.theme.popContrastColor};
	border: none;
	border-radius: ${p => p.theme.borderRadius};
	outline: none;
	font: inherit;
	cursor: pointer;

	&:focus {
		box-shadow: 0 0 0 4px ${p => transparentize(0.6, p.theme.popBackground)};
	}
`;

const SearchResultIcon = styled(Icon)`
	width: 16px;
	height: 16px;
	padding: 0 2px;
	margin-left: 0.5em;
`;

const SearchResultListing = (props: SearchResultListingProps) => {
	const results = props.results.map((result, idx) => (
		<SearchResultEntry
			key={idx}
			onClick={() => props.onSelect(result)}
		>
			{result.name}
			<SearchResultIcon name="arrow-right" />
		</SearchResultEntry>
	));

	return (
		<SearchResultListingWrapper>
			{results}
		</SearchResultListingWrapper>
	);
};

const AttendeeSearchResults = (props: AttendeeSearchResultsProps) => {
	const selectionMap = useMemo(
		() => {
			const map = {} as Record<string, Attendee>;

			for (const sel of props.selection)
				map[sel.name] = sel;

			return map;
		},
		[props.selection]
	);

	const teammates = props.results
			.filter(attendee => attendee.type === "teammate" && !selectionMap.hasOwnProperty(attendee.name)),
		sharedCalendars = props.results
			.filter(attendee => attendee.type === "shared-calendar" && !selectionMap.hasOwnProperty(attendee.name));
	let teammatesContent,
		sharedCalendarsContent;

	if (teammates.length) {
		teammatesContent = (
			<SearchResultListing
				results={teammates}
				onSelect={props.onSelect}
			/>
		);
	} else
		teammatesContent = <Centered>No results</Centered>;

	if (props.teammatesOnly)
		sharedCalendarsContent = <Centered>Shared calendars cannot be selected for this event type</Centered>;
	else if (sharedCalendars.length) {
		sharedCalendarsContent = (
			<SearchResultListing
				results={sharedCalendars}
				onSelect={props.onSelect}
			/>
		);
	} else
		sharedCalendarsContent = <Centered>No results</Centered>;

	return (
		<AttendeeSearchResultsWrapper>
			<SearchResultBox title="Teammates">
				{teammatesContent}
			</SearchResultBox>
			<SearchResultBox title="Shared Calendars">
				{sharedCalendarsContent}
			</SearchResultBox>
		</AttendeeSearchResultsWrapper>
	);
};

const AttendeeSearchBox = (props: AttendeeSearchBoxProps) => {
	const [query, setQuery] = useState("");
	const [expanded, setExpanded] = useState(false);
	const [searching, setSearching] = useState(false);
	const [results, setResults] = useState([] as Attendee[]);

	const expand = () => {
		setExpanded((previous) => {
			if (previous) return previous;
			doSearch();
			return true;
		});

	};

	const doSearch = async () => {
		let normalizedQuery = query.trim();

		// query box is empty. nothing to do, hide.
		if (!normalizedQuery) {
			setSearching(false);
			setExpanded(false);
			return;
		}

		// we have a query to run
		setSearching(true);

		const response = await request({
			url: "employee/attendees/list",
			query: {
				query: normalizedQuery,
				size: 20
			},
			headers: {
				"Content-Type": "application/json",
				Authorization: props.authData.state.accessToken?.value || "",
				"Employee-Id": props.authData.employeeId
			}
		});

		if (response.success) {
			const attendees = props.teammatesOnly ?
				response.data.teammates :
				response.data.teammates.concat(response.data.sharedCalendars);

			setResults(attendees);
		} else
			setResults([]);

		setSearching(false);
		setExpanded(true);
	};

	let resultBox = null;

	if (expanded) {
		let resultContent;

		if (searching) {
			resultContent = (
				<AttendeeSearchLoadingWrapper>
					<LoadingIcon />
				</AttendeeSearchLoadingWrapper>
			);
		} else if (results.length) {
			resultContent = (
				<AttendeeSearchResults
					results={results}
					onSelect={ (e) => {
							setExpanded(false);
							return props.onSelect(e);
						}
					}
					selection={props.selection}
					teammatesOnly={props.teammatesOnly}
				/>
			);
		} else
			resultContent = <Centered>No results</Centered>;

		resultBox = (
			<AttendeeSearchResultBox>
				{resultContent}
			</AttendeeSearchResultBox>
		);
	}

	useEffect(
		() => {
			const handleClick = () => {
				setExpanded(false);
			};

			document.body.addEventListener("click", handleClick);

			return () => {
				document.body.removeEventListener("click", handleClick);
			};
		},
		[]
	);

	return (
		<AttendeeSearchBoxWrapper onClick={evt => evt.stopPropagation()}>
			<AttendeeSearchForm>
				<AttendeeSearchInput
					accented
					value={query}
					onChange={e => {setQuery(e.value);}}
					onFocus={expand}
					placeholder={props.prompt}
				/>
				<Button
					square
					onClick={doSearch}
				>
					Search
				</Button>
			</AttendeeSearchForm>
			{resultBox}
		</AttendeeSearchBoxWrapper>
	);
};

const AttendeeListingsBox = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

const AttendeeListingWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const AttendeeListingHead = styled.div`
	display: flex;
	align-items: flex-end;
	flex-shrink: 0;
	gap: 10px;
	padding-bottom: 5px;
`;

const AttendeeListingTitle = styled.div`
	display: flex;
	align-items: center;
	flex-grow: 1;
	height: 24px;
	font-weight: 600;
	border-bottom: 1px solid ${p => p.theme.separatorColor};
`;

const AttendeeListingContent = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

const AttendeeListing = (props: AttendeeListingProps) => {
	const allRequired = useMemo(
		() => props.attendees.every(p => p.required),
		[props.attendees]
	);

	const dispatchToggle = (attendee: Attendee) => {
		const outAttendees = props.attendees.map(p => {
			if (p !== attendee)
				return p;

			return {
				...p,
				required: !p.required
			};
		});

		props.onChange(outAttendees);
	};

	const dispatchToggleAll = () => {
		const outAttendees = props.attendees.map(p => ({
			...p,
			required: !allRequired
		}));

		props.onChange(outAttendees);
	};

	const dispatchDelete = (attendee: Attendee) => {
		const outAttendees = props.attendees
			.filter(p => p !== attendee);

		props.onChange(outAttendees);
	};

	const check = props.checkable ?
		(
			<Check
				value={allRequired}
				label="All Required"
				onChange={dispatchToggleAll}
			/>
		) :
		null;

	const attendees = props.attendees.map((attendee, idx) => (
		<AttendeeEntry
			key={idx}
			attendee={attendee}
			onToggle={dispatchToggle}
			onDelete={dispatchDelete}
			checkable={props.checkable}
		/>
	));

	return (
		<AttendeeListingWrapper>
			<AttendeeListingHead>
				<AttendeeListingTitle>{props.title}</AttendeeListingTitle>
				{check}
			</AttendeeListingHead>
			<AttendeeListingContent>
				{attendees}
			</AttendeeListingContent>
		</AttendeeListingWrapper>
	);
};

const AttendeeEntryWrapper = styled.div`
	display: flex;
	align-items: center;
	padding: 2px;
	background: ${p => p.theme.popBackground};
	color: ${p => p.theme.popContrastColor};
	border-radius: ${p => p.theme.borderRadius};
	margin: 0 5px 5px 0;
`;

const AttendeeEntryContent = styled.div`
	margin: 0 4px 0 8px;
`;

const AttendeeEntryDeleteButton = styled.button`
	width: 20px;
	height: 20px;
	padding: 0 5px;
	background: transparent;
	color: inherit;
	border: none;
	outline: none;
	cursor: pointer;
`;

const AttendeeEntry = (props: AttendeeEntryProps) => {
	const check = props.checkable ?
		(
			<Check
				small
				inverted
				value={props.attendee.required}
				onChange={() => props.onToggle(props.attendee)}
			/>
		) :
		null;

	return (
		<AttendeeEntryWrapper>
			{check}
			<AttendeeEntryContent>
				{props.attendee.name}
			</AttendeeEntryContent>
			<AttendeeEntryDeleteButton
				onClick={() => props.onDelete(props.attendee)}
			>
				<Icon name="cross" />
			</AttendeeEntryDeleteButton>
		</AttendeeEntryWrapper>
	);
};

const AttendeeSelector = (props: AttendeeSelectorProps) => {
	const teammates = props.attendees
			.filter(attendee => attendee.type === "teammate"),
		sharedCalendars = props.attendees
			.filter(attendee => attendee.type === "shared-calendar");
	const clickEvent = useGAEventsTracker('Event Configuration');
	let teammateListing = null,
		sharedCalendarListing = null,
		listings = null;

	const dispatchChange = (...sources: Attendee[][]) => {
		props.onChange(([] as Attendee[]).concat(...sources));
	};

	const handleSearchSelect = (attendee: Attendee) => {
		if (attendee.type === "shared-calendar") {
			clickEvent('Search for teammates/attendees');
			dispatchChange(teammates, [attendee]);
		 } else {
			clickEvent('Search for shared calendars/teammates/attendees');
			dispatchChange(teammates, sharedCalendars, [attendee]);
		}
	};

	if (teammates.length) {
		teammateListing = (
			<AttendeeListing
				checkable
				key="teammates"
				title="Teammates"
				attendees={teammates}
				onChange={p => dispatchChange(p, sharedCalendars)}
			/>
		);
	}

	if (sharedCalendars.length) {
		sharedCalendarListing = (
			<AttendeeListing
				key="shared-calendars"
				title="Shared Calendars"
				attendees={sharedCalendars}
				onChange={p => dispatchChange(teammates, p)}
			/>
		);
	}

	if (teammateListing || sharedCalendarListing) {
		listings = (
			<AttendeeListingsBox>
				{teammateListing}
				{sharedCalendarListing}
			</AttendeeListingsBox>
		);
	}

	return (
		<AttendeeSelectorBox>
			<AttendeeSearchBox
				prompt={props.prompt}
				onSelect={handleSearchSelect}
				selection={props.attendees}
				teammatesOnly={props.teammatesOnly}
				authData={props.authData}
			/>
			{listings}
		</AttendeeSelectorBox>
	);
};

const ScheduleSelectorBox = styled.article`
	display: flex;
	flex-direction: column;
	gap: 15px;
	border: 1px solid ${p => p.theme.separatorColor};
	border-radius: ${p => p.theme.borderRadius};
	padding: 15px;
`;

const ScheduleSelectorTip = styled(Tip)`
	padding: 20px;
	justify-content: center;
	border-radius: ${p => p.theme.borderRadius};
`;

interface ScheduleSelectorProps {
	settings: EventSettings;
	scheduled: ScheduledPoint[];
	onChange: (scheduled: ScheduledPoint[]) => void;
}

interface ScheduleListingProps {
	settings: EventSettings;
	scheduled: ScheduledPoint[];
	onChange: (scheduled: ScheduledPoint[]) => void;
}

interface ScheduleEntryProps {
	point: ScheduledPoint;
	settings: EventSettings;
	onEdit: (point: ScheduledPoint) => void;
	onDelete: (point: ScheduledPoint) => void;
}

const ScheduledListingWrapper = styled.div`
	display: grid;
	grid-template-columns: auto 1fr;
	gap: 10px 60px;
`;

const ScheduleListing = (props: ScheduleListingProps) => {
	const entries = props.scheduled.map((point, idx) => (
		<ScheduleEntry
			key={`${props.scheduled.length}-${idx}`}
			point={point}
			settings={props.settings}
			onEdit={pt => dispatchEdit(idx, pt)}
			onDelete={() => dispatchDelete(idx)}
		/>
	));

	const dispatchDelete = (idx: number) => {
		const outScheduled = props.scheduled
			.filter((_, i) => i !== idx);

		props.onChange(outScheduled);
	};

	const dispatchEdit = (idx: number, point: ScheduledPoint) => {
		const outScheduled = props.scheduled.map((pt, i) => {
			return i === idx ?
				point :
				pt;
		})

		props.onChange(outScheduled);
	};

	return (
		<ScheduledListingWrapper>
			{entries}
		</ScheduledListingWrapper>
	);
};

const ScheduleEntryLeftWrapper = styled.div`
	display: flex;
	gap: 10px;
`;

const ScheduleEntryRightWrapper = styled.div`
	display: flex;
	gap: 10px;
`;

const ScheduledEntryTypeSelect = styled(Select)`
	min-width: 150px;
`;

const ScheduledEntryRecurrenceSelect = styled(Select)`
	flex-grow: 1;
`;

const ScheduleEntryDeleteButton = styled(DeleteButton)`
	width: 35px;
	height: 35px;
	padding: 8px;
`;

const resolveRecurrenceOptions = (
	point: ScheduledPoint,
	settings: EventSettings
): Option<RecurrenceMode | null>[] => {
	const options = [] as Option<RecurrenceMode | null>[];

	const tz = TIMEZONES.find(t => t.zone === settings.timeZone),
		zone = tz ? tz.zone : "America/Pacific",
		tzName = tz ? tz.shortName : "PST";

	if (point.type === "time") {
		const timeLabel = `at ${printTime(point.hour, point.minute)} ${tzName}`;

		if (point.day === null) {
			options.push({
				value: "weekday",
				label: `Every Weekday ${timeLabel}`
			});
		} else {
			options.push({
				value: "weekly",
				label: `Every ${DAYS[point.day]} ${timeLabel}`
			});
		}
	} else {
		const time = getLocalizedTime(point.start, zone),
			dateTimeLabel = `at ${printTime(time.hour, time.minute)} ${tzName} (From ${printDate(time.year, time.month + 1, time.day)})`;

		options.push({
			value: null,
			label: "No Recurrence"
		});

		options.push({
			value: "weekday",
			label: `Every Weekday ${dateTimeLabel}`
		});

		options.push({
			value: "weekly",
			label: `Every ${DAYS[time.dayIndex]} ${dateTimeLabel}`
		});

		options.push({
			value: "weekly-other",
			label: `Every Other ${DAYS[time.dayIndex]} ${dateTimeLabel}`
		});
	}

	return options;
};

const ScheduleEntry = (props: ScheduleEntryProps) => {
	const [point, setPoint] = useState(props.point);
	const [recurrenceOptions, setRecurrenceOptions] = useState(
		() => resolveRecurrenceOptions(props.point, props.settings) as Option<RecurrenceMode | null>[]
	);
	const updateCachedTimeZone = useState(props.settings.timeZone)[1];

	const updatePoint = (
		key: keyof ScheduledTime | keyof ScheduledExactTime,
		value: any
	) => {
		const getNewPoint = (pt: ScheduledPoint) => {
			if (key === "type") {
				const time = getScheduledTime(pt, props.settings.timeZone);

				switch (value) {
					case "time":
						return {
							type: "time",
							hour: time.hour,
							minute: time.minute,
							day: null,
							recurrence: null
						} as ScheduledTime;

					case "exact-time":
						return {
							type: "exact-time",
							start: time.date.toISOString(),
							recurrence: null
						} as ScheduledExactTime;
				}
			}

			return {
				...pt,
				[key]: value
			};
		};

		setPoint(pt => {
			const newPoint = getNewPoint(pt);

			setRecurrenceOptions(
				resolveRecurrenceOptions(newPoint, props.settings)
			);

			props.onEdit(newPoint);
			return newPoint;
		});
	};

	let specificInputs;

	if (point.type === "time") {
		const setTime = (evt: FormEvent<string>) => {
			const [
				hour,
				minute
			] = parseTimeString(evt.value);

			updatePoint("hour", hour);
			updatePoint("minute", minute);
		};

		const setDay = (evt: FormEvent<number | null>) => {
			updatePoint("day", evt.value);
		};

		specificInputs = (
			<>
				<TimeInput
					accented
					step={15 * 60}
					value={mkTimeString(point.hour, point.minute)}
					onChange={setTime}
				/>
				<LinearSelect
					accented
					value={point.day}
					options={FULL_DAY_OPTIONS}
					onChange={setDay}
					hash={option => String(option.value)}
				/>
			</>
		);
	} else {
		const time = getLocalizedTime(
			point.start,
			props.settings.timeZone
		);

		const setStart = (time: Partial<Time>) => {
			const t = getLocalizedTime(
				point.start,
				props.settings.timeZone
			);

			const overridden = {
				...t,
				...time
			} as Time;

			const date = mkDate(
				overridden.year,
				overridden.month,
				overridden.day,
				overridden.hour,
				overridden.minute,
				props.settings.timeZone
			);

			updatePoint("start", date.toISOString());
		};

		const setTime = (evt: FormEvent<string>) => {
			const [
				hour,
				minute
			] = parseTimeString(evt.value);

			setStart({
				hour,
				minute
			});
		};

		const setDate = (evt: FormEvent<string>) => {
			const [
				year,
				month,
				day
			] = parseDateString(evt.value);

			if (isNaN(year) || isNaN(month) || isNaN(day))
				return;

			setStart({
				year,
				month: month - 1,
				day
			});
		};

		specificInputs = (
			<>
				<TimeInput
					accented
					step={15 * 60}
					value={mkTimeString(time.hour, time.minute)}
					onChange={setTime}
				/>
				<DateInput
					accented
					value={mkDateString(time.year, time.month + 1, time.day)}
					onChange={setDate}
				/>
			</>
		);
	}

	useEffect(
		() => {
			updateCachedTimeZone(tz => {
				if (point.type === "time")
					updatePoint("hour", point.hour);
				else {
					const time = getLocalizedTime(point.start, tz),
						date = mkDate(time, props.settings.timeZone);

					updatePoint("start", date.toISOString());
				}

				return props.settings.timeZone;
			});
		},
		[props.settings.timeZone]
	);

	return (
		<>
			<ScheduleEntryLeftWrapper>
				{specificInputs}
			</ScheduleEntryLeftWrapper>
			<ScheduleEntryRightWrapper>
				<ScheduledEntryRecurrenceSelect
					accented
					autoSet
					value={point.recurrence}
					options={recurrenceOptions}
					onChange={e => updatePoint("recurrence", e.value)}
				/>
				<ScheduleEntryDeleteButton
					square
					onClick={props.onDelete}
				>
					<Icon name="trash" />
				</ScheduleEntryDeleteButton>
			</ScheduleEntryRightWrapper>
		</>
	);
};

const ScheduleControls = styled.div`
	display: flex;
	gap: 10px;
	border-top: 1px solid ${p => p.theme.separatorColor};
	padding-top: 15px;
`;

const ScheduleSelector = (props: ScheduleSelectorProps) => {
	const addTime = () => {
		const entry = {
			type: "time",
			hour: 0,
			minute: 0,
			day: null,
			recurrence: null
		} as ScheduledTime;

		props.onChange([...props.scheduled, entry]);
	};

	const addExactTime = () => {
		const date = new Date(),
			timestamp = date.getTime(),
			roundedTimestamp = Math.floor(timestamp / (15 * 60 * 1000)) * (15 * 60 * 1000);

		const entry = {
			type: "exact-time",
			start: new Date(roundedTimestamp).toISOString(),
			recurrence: null
		} as ScheduledExactTime;

		props.onChange([...props.scheduled, entry]);
	};

	let content;

	if (props.scheduled.length) {
		content = (
			<ScheduleListing
				scheduled={props.scheduled}
				settings={props.settings}
				onChange={props.onChange}
			/>
		);
	} else {
		content = (
			<ScheduleSelectorTip>
				Times based on availability unless a specific time is selected below
			</ScheduleSelectorTip>
		);
	}

	return (
		<ScheduleSelectorBox>
			{content}
			<ScheduleControls>
				<Button
					square
					onClick={addExactTime}
				>
					Add Time
				</Button>
			</ScheduleControls>
		</ScheduleSelectorBox>
	);
};

const EventEditor = (props: EventEditorProps) => {
	const { authState } = useOktaAuth();
	const e = props.event;

	const [settings, setSettings] = useState(() => ({
		type: e.type,
		duration: e.duration,
		customDuration: e.customDuration,
		title: e.title,
		aliasLink: e.aliasLink,
		timeZone: e.timeZone,
		venue: e.venue,
		topic: e.topic,
		description: e.description,
		requireDescription: e.requireDescription,
		oneTimeExpiration: e.oneTimeExpiration
	} as EventSettings));

	const [attendees, setAttendees] = useState(e.attendees);
	const [scheduled, setScheduled] = useState(e.scheduled);
	const employeeId = useAppSelector(state => state.client.employee!.id);
	const clickEvent = useGAEventsTracker('Event Editor');

	const updateSettings = (s: EventSettings) => {
		setSettings(currentSettings => {
			if (s.type === currentSettings.type)
				return s;

			const out = {
				...s
			};

			switch (s.type) {
				case "office-hour":
					out.duration = 60;
					out.venue = "zoom";
					break;
			}

			return out;
		});
	};

	const dispatchSave = async() => {
		const event = {
			...e,
			...settings,
			attendees,
			scheduled,
			recurring: scheduled.some(point => point.recurrence !== null)
		} as ClientEventTemplate;

		if (!event.title) {
			props.onError('Event is missing Title.');
			return;
		}

		if(event.duration === -1) {
			/* TODO: Custom formik handler to distinguish types */
			/* Convert string to number; formik requires a custom handler otherwise */
			event.customDuration = Number(event.customDuration)

			if(event.customDuration % 1 !== 0) {
				return props.onError('Duration must be a whole number.');
			}
			if(event.customDuration < 15) {
				return props.onError('Duration must be at least 15 minutes.');
			}
		}

		if (event?.aliasLink) {
			const isValidLength = event.aliasLink.length >= 3;//have to have at least 3 characters.
			if (!isValidLength) {
				props.onError('Link Id must have 3 or more characters.');
				return;
			}

			const hasSpacesInLinkId = event.aliasLink.split(' ').length !== 1;//no spaces
			if (hasSpacesInLinkId) {
				props.onError('Link ID must not have spaces');
				return;
			}

			const invalidCharsREGEX = /[^A-Za-z0-9_.~-]+/g;//Only unreserved URL characters allowed as Id. Characters have to be a-Z, 0-9, - . _ ~. No spaces
			const isInvalidLinkFormat = invalidCharsREGEX.test(event.aliasLink);//-1 means no invalid characters
			if (isInvalidLinkFormat) {
				props.onError('Invalid characters. Link ID can only use a-Z, 0-9, - . _ ~. No spaces');
				return;
			}
			//check if it is valid to create/overwrite link
			const isValidCreateOrOverwrite = await validateAliasLink(event);
			if (!isValidCreateOrOverwrite) {
				props.onError('Invalid Link Id. Link name exists and is not overwrittable');
				return;
			}
			clickEvent('Create Link Alias');
		}

		props.onSave(event);
	};

	const validateAliasLink = async (event: ClientEventTemplate) => {
		if (!event.aliasLink) return false;

		const response = await request({
			url: "employee/events/link",
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: authState.accessToken?.value || ""
			},
			body: {
				event,
				employeeId
			}
		});
		return response.data.isValid;
	}

	let participantSelector = null;

	if (settings.type !== "me") {
		const prompt = settings.type === "team" ?
			"Type to add teammates" :
			"Type to add teammates and/or a shared calendar";

		participantSelector = (
			<AttendeeSelector
				prompt={prompt}
				teammatesOnly={settings.type === "team"}
				attendees={attendees}
				onChange={setAttendees}
				authData={props.authData}
			/>
		);
	}

	return (
		<EventEditorWrapper>
			<EventEditorContent>
				<Formik
					initialValues={settings}
					enableReinitialize
					onSubmit={() => {}}
				>
					<EventInfoForm
						settings={settings}
						onChange={updateSettings}
					/>
				</Formik>
				{participantSelector}
				<ScheduleSelector
					settings={settings}
					scheduled={scheduled}
					onChange={setScheduled}
				/>
			</EventEditorContent>
			<EventSaveWrapper>
				<Button
					type="submit"
					square
					disabled={props.disabled}
					onClick={dispatchSave}
				>
					Save
				</Button>
			</EventSaveWrapper>
		</EventEditorWrapper>
	);
};

const AvailabilityCard = styled(Card)`
	display: flex;
	overflow: hidden;
	max-height: 600px;
`;

const FooterWrapper = styled.div`
	margin-top: 15px;
`;

const collectAvailabilitySlots = (
	availability: Availability | null
): TimeEvent[] => {
	const slots: TimeEvent[] = [];

	if (!availability)
		return slots;

	availability.forEach((day, i) => {
		for (const range of day.ranges) {
			// January 3 2050 will be a Monday, which is the only
			// piece of information we are concerned about for
			// presentational purposes
			const startDate = new Date(Date.UTC(
				2050,
				0,
				3 + i,
				~~(range.start / 100),
				range.start % 100
			));

			const endDate = new Date(Date.UTC(
				2050,
				0,
				3 + i,
				~~(range.end / 100),
				range.end % 100
			));

			slots.push(
				TimeEvent.resolve({
					type: "entry",
					start: startDate,
					end: endDate,
					showInGui: true,
					eventType: "me",
					topic: ""
				})
			);
		}
	});

	return slots;
};

const getAvailability = (
	availabilitySlots: TimeEvent[]
): Availability | null => {
	if (!availabilitySlots.length)
		return null;

	const codes = getDayCodes(),
		availability = [] as AvailabilityDay[];
	let slotIdx = 0;

	for (const code of codes) {
		const av: AvailabilityDay = {
			ranges: []
		};

		while (true) {
			const slot = availabilitySlots[slotIdx];
			if (!slot || getUtcDayCode(slot.start) !== code)
				break;

			const start = new Date(slot.start),
				end = new Date(slot.end);

			av.ranges.push({
				start: start.getUTCHours() * 100 + start.getUTCMinutes(),
				end: (end.getUTCHours() * 100 + end.getUTCMinutes()) || 2400
			});

			slotIdx++;
		}

		availability.push(av);
	}

	return availability as Availability;
};

const getDayCodes = (): number[] => {
	const codes = [] as number[];

	for (let i = 0; i < DAYS_TO_DISPLAY; i++) {
		codes.push(
			getUtcDayCode(Date.UTC(
				2050,
				0,
				3 + i
			))
		);
	}

	return codes;
};

const getScheduledTime = (
	point: ScheduledPoint,
	localTimeZone: string,
	remoteTimeZone?: string
): Time => {
	if (point.type === "time") {
		const tz = remoteTimeZone ?
				remoteTimeZone :
				localTimeZone,
			timeNow = getLocalizedTime(new Date(), tz),
			date = mkDate(
				timeNow.year,
				timeNow.month,
				timeNow.day,
				point.hour,
				Math.floor(point.minute / 15) * 15,
				tz
			);

		return getLocalizedTime(date, localTimeZone);
	} else {
		const date = new Date(point.start),
			timestamp = date.getTime(),
			roundedTimestamp = Math.floor(timestamp / (15 * 60 * 1000)) * (15 * 60 * 1000);

		return getLocalizedTime(roundedTimestamp, localTimeZone);
	}
};

let DAYS_TO_DISPLAY: number;

const Account = () => {
	const { authState } = useOktaAuth();

	const currentSettings = useAppSelector(state => {
		const {
			email,
			events,
			serviceOn,
			weekendsOn,
			availability,
			availabilityTimeZone,
			bookingBuffer
		} = state.client.employee!;

		return {
			email,
			events,
			serviceOn,
			weekendsOn,
			availability,
			availabilityTimeZone,
			bookingBuffer
		};
	});

	const employeeId = useAppSelector(state => state.client.employee!.id);
	const dispatch = useAppDispatch();

	const [availabilitySlots, setAvailabilitySlots] = useState(
		() => collectAvailabilitySlots(currentSettings.availability as Availability | null)
	);

	const [eventIndex, setEventIndex] = useState(-1);
	const [serviceOn, setServiceOn] = useState(currentSettings.serviceOn);
	const [weekendsOn, setWeekendsOn] = useState(currentSettings.weekendsOn || false);
	const [availabilityTimeZone, setAvailabilityTimeZone] = useState(currentSettings.availabilityTimeZone);
	const [bookingBuffer, setBookingBuffer] = useState(currentSettings.bookingBuffer || 4);
	const [editingEvent, setEditingEvent] = useState(null as ClientEventTemplate | null);
	const [savingEvent, setSavingEvent] = useState(false);
	const [savingSettings, setSavingSettings] = useState(false);
	const [pendingDeletions, setPendingDeletions] = useState([] as string[]);
	const [messageApi, contextHolder] = message.useMessage();
	const clickEvent = useGAEventsTracker('Accounts');

	DAYS_TO_DISPLAY = weekendsOn ? Object.entries(ACTIVE_DAYS).length : Object.entries(ACTIVE_WEEK_DAYS).length;

	const startEventEdit = (event: ClientEventTemplate) => {
		const idx = currentSettings.events.indexOf(event);

		const clonedEvent = {
			...event,
			oneTimeExpiration: event.oneTimeExpiration || 1440,
			attendees: event.attendees.map(a => ({ ...a })),
			scheduled: event.scheduled.map(s => ({ ...s })),
		};

		setEditingEvent(clonedEvent);
		setEventIndex(idx);
	};

	const startNewEventEdit = () => {
		setEditingEvent({
			id: "",
			type: "me",
			title: "",
			timeZone: availabilityTimeZone,
			description: "",
			attendees: [],
			scheduled: [],
			topic: "",
			duration: null,
			customDuration: null,
			venue: null,
			recurring: false,
			deletable: true,
			requireDescription: false,
			staticLink: "",
			dynamicLink: "",
			aliasLink: "",
			deleted: false,
			created: "",
			zoomLinkOverride: "",
			zoomPasswordOverride: "",
			webinarId: null,
			oneTimeExpiration: 1440
		});
		setEventIndex(currentSettings.events.length);
	};

	const cancelEventEdit = () => {
		setEditingEvent(null);
	};

	const saveEvent = async (event: ClientEventTemplate) => {
		const idx = eventIndex;
		if (idx === -1)
			return;

		setSavingEvent(true);

		const payload: any = {
			employeeId,
			event
		};
		let url: string;

		if (event.id) {
			url = "employee/events/update";
			payload.eventId = event.id;
			clickEvent('Update Existing Booking Event');
		} else {
			url = "employee/events/create";
			clickEvent('Create New Booking Event');
		}

		const response = await request({
			url,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: authState.accessToken?.value || ""
			},
			body: payload
		});

		setSavingEvent(false);

		if (response.success) {
			const updatedEvents = currentSettings.events.slice();
			updatedEvents[idx] = response.data;
			dispatch(setEvents(updatedEvents));
			setEventIndex(-1);
			setEditingEvent(null);
		}
	};

	const updateEvent = (event: ClientEventTemplate) => {
		const updatedEvents = currentSettings.events.map(evt => {
			return evt.id === event.id ?
				event :
				evt;
		});
		dispatch(setEvents(updatedEvents));
	};

	const deleteEvent = async (event: ClientEventTemplate) => {
		setPendingDeletions(deletions => deletions.concat(event.id));

		const response = await request({
			url: "employee/events/delete",
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: authState.accessToken?.value || ""
			},
			body: {
				employeeId,
				eventId: event.id
			}
		});

		setPendingDeletions(deletions => deletions.filter(del => del !== event.id));

		if (response.success) {
			clickEvent('Delete Booking Event');
			const updatedEvents = currentSettings.events.filter(evt => evt !== event);
			dispatch(setEvents(updatedEvents));
		}
	};

	const addAvailability = (event: SelectEvent) => {
		const newSlots = [...availabilitySlots, event.event];
		newSlots.sort((e, e2) => e.start - e2.start);
		clickEvent('Pending: Add New Availability');
		setAvailabilitySlots(newSlots);
	};

	const updateAvailability = (event: UpdateEvent) => {
		const newSlots = availabilitySlots.map(evt => evt === event.oldEvent ? event.event : evt);
		clickEvent('Pending: Update Availability');
		setAvailabilitySlots(newSlots);
	};

	const removeAvailability = (event: RemoveEvent) => {
		const newSlots = availabilitySlots.filter(evt => evt !== event.event);
		clickEvent('Pending: Remove Availability');
		setAvailabilitySlots(newSlots);
	};

	const spreadAvailability = (event: DaySelectEvent) => {
		const newSlots = [] as TimeEvent[];

		const slice = event.selection.filter(sel => {
			return getUtcDayCode(sel.start) === event.day.startTime.dayCode;
		});

		for (let i = 0; i < DAYS_TO_DISPLAY; i++) {
			for (const s of slice) {
				const startDate = new Date(s.start);


				const outStartDate = Date.UTC(
					2050,
					0,
					3 + i,
					startDate.getUTCHours(),
					startDate.getUTCMinutes()
				);

				const slot = TimeEvent.resolve({
					type: "entry",
					start: outStartDate,
					duration: s.duration,
					showInGui: true,
					eventType: "me",
					topic: s.topic
				});

				newSlots.push(slot);
			}
		}
		clickEvent('Pending: Spread Availability');
		setAvailabilitySlots(newSlots);
	};

	const dispatchSaveSettings = async () => {
		const payload = {
			employeeId,
			serviceOn,
			weekendsOn,
			availability: getAvailability(availabilitySlots),
			availabilityTimeZone,
			bookingBuffer
		};

		setSavingSettings(true);

		const response = await request({
			url: "employee/settings/save",
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: authState.accessToken?.value || ""
			},
			body: payload
		});

		setSavingSettings(false);

		if (response.success) {
			clickEvent('Save Availability Settings');
			dispatch(setEmployee(response.data));
		}
	};
	//@ts-nocheck
	const updateBookingBuffer = (bufferSize: any) => {
		setBookingBuffer(bufferSize);
	}

	const frame = {
		startDate: Date.UTC(2050, 0, 3),
		extent: DAYS_TO_DISPLAY,
		days: weekendsOn ? ACTIVE_DAYS : ACTIVE_WEEK_DAYS,
		timeZone: "UTC",
		presentationTimeZone: availabilityTimeZone
	};

	const authData = {
		employeeId,
		state: authState
	};

	const showErrorMessage = (message = 'Link Alias already exists please update and try again.') => {
		messageApi.open({
			type: 'error',
			duration: 15,
			content: message,
			className: 'error-message',
			style: {
				position: 'relative',
				zIndex: '10000'
			}
		});
	}

	const toggleWeekends = (checked: boolean) => {
		return setWeekendsOn(checked);
	};

	const alertMessage = (
		<p>
			The BookMe tool will be migrated to Zoom Scheduler in Q1. If you have a unique scheduling use case and would like to share more about your experience, <a href='https://docs.google.com/forms/d/e/1FAIpQLScjfM6QAx5Pi93TK0zCX3rBwigXPqud7YGRVEtZWPvvrx-sSQ/viewform?usp=sf_link' target="_blank"> Please click here to sign up for a short listening session.</a>
		</p>
	);

	return (
		<>
			<Alert message={alertMessage} type="warning" showIcon={true} />
			{contextHolder}
			<AccountWrapper>
				<h2>General Settings</h2>
				<h3>Application mode</h3>
				<SettingBox>
					<p>Toggle your availability for booking.</p>
					<Toggle
						value={serviceOn}
						onChange={() => setServiceOn(!serviceOn)}
					/>
				</SettingBox>
				<SettingBox>
					<p>Configure minimum booking buffer.</p>
					<Select
					accented
					name="buffer"
					value={bookingBuffer}
					options={BUFFER_TIME_OPTIONS}
					onChange={e => updateBookingBuffer(e.value)}
					/>
				</SettingBox>
				<SettingBox>
					<h2>My Events</h2>
					<p>These are preconfigured links to help you schedule events on your terms.</p>
					<EventsCard>
						<EventsTable
							employeeId={employeeId}
							events={currentSettings.events}
							localTimeZone={availabilityTimeZone}
							pendingDeletions={pendingDeletions}
							onUpdate={updateEvent}
							onDelete={deleteEvent}
							onEventSelect={startEventEdit}
							onEventTrigger={startNewEventEdit}
						/>
					</EventsCard>
				</SettingBox>
				<SettingBox>
					<h2>Availability</h2>
					<div>
						<p>
						Set your daily availability here. Blue regions denote your available hours in your time zone. Your current time zone is
						<strong> {(TIMEZONES.find(tzd => tzd.zone === availabilityTimeZone) || {}).name || "unknown"}</strong>
						.
						</p>
						<WeekendContainer>
							<strong>Weekends</strong> <Switch checked={weekendsOn} size='small' onChange={toggleWeekends} />
						</WeekendContainer>
					</div>
					<AvailabilityCard>
						<ThemeProvider theme={availabilityTheme}>
							<CalendarView
								fill
								sinkIn
								daysOnly
								resizable
								removable
								selectable
								autoScroll={false}
								frame={frame}
								selection={availabilitySlots}
								disabled={!serviceOn}
								reload={0}
								onSelect={addAvailability}
								onUpdate={updateAvailability}
								onRemove={removeAvailability}
								onDaySelect={spreadAvailability}
								onTimeZoneSelect={evt => setAvailabilityTimeZone(evt.value)}
								bookingBuffer={bookingBuffer}
								weekendsOn={weekendsOn}
							/>
						</ThemeProvider>
					</AvailabilityCard>
				</SettingBox>
				<FooterWrapper>
					<Button
						square
						disabled={savingSettings}
						onClick={dispatchSaveSettings}
					>
						Save
					</Button>
				</FooterWrapper>
				<EventEditorModal
					title="Edit Event"
					open={Boolean(editingEvent)}
					onCloseRequest={cancelEventEdit}
				>
					<EventEditor
						event={editingEvent!}
						disabled={savingEvent}
						onSave={saveEvent}
						onError={message => showErrorMessage(message)}
						authData={authData}
					/>
				</EventEditorModal>
			</AccountWrapper>
		</>
	);
};

export default Account;
